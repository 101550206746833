 @font-face {
     font-family: tg;
     src: url("../public/texgyreadventor-regular.otf");
 }

 html,
 body,
 #root,
 #root>div {
     height: 100%;
     width: 100%;
     font-family: tg;
 }

 #root {
     z-index: 1;
     position: absolute;
     top: 0;
     left: 0;
     /* overflow: hidden; */
 }

 html {
     font-family: tg;
     /* overflow: scroll; */
     -ms-overflow-style: none;
     scrollbar-width: none;
     cursor: pointer;
 }

 .AboutData>p>a {
     color: black;
 }

 .AboutData {
     overflow-y: scroll;
     -ms-overflow-style: none;
     scrollbar-width: none;
 }

 .AboutData::-webkit-scrollbar {
     display: none;
 }

 .App {
     display: flex;
     overflow: hidden;
 }

 :root {
     --PageWidth: 86.5%;
 }

 /* Hide scrollbar for Chrome, Safari and Opera */
 Html::-webkit-scrollbar {
     display: none;
 }

 .mainPage {
     /* background-color: white; */
 }



 .Items:hover {
     cursor: pointer;
 }

 .Items {}


 .Section {
     margin-bottom: 10%;
 }

 .Columns {
     width: 80%;
     height: 100%;
 }

 .PhotoColumn {
     overflow-y: scroll;
 }

 .cimg {
     width: 100%;
     height: auto;
 }

 /* Hide scrollbar for Chrome, Safari and Opera */
 .PhotoColumn::-webkit-scrollbar {
     display: none;
 }

 /* Hide scrollbar for IE, Edge and Firefox */
 .PhotoColumn {
     -ms-overflow-style: none;
     /* IE and Edge */
     scrollbar-width: none;
     /* Firefox */
 }

 .c {
     position: absolute;
     top: 0;
     left: 0;
     z-index: -1;
     visibility: hidden;
     overflow: hidden;
 }

 canvas {
     position: absolute;
     /* top:50%;
    left:50%; */
     z-index: 2;
     overflow: hidden;
 }

 .canvas-container,
 .cw {
     overflow: hidden;
 }

 .Sprawl {
     width: 100%;
     height: 100%;
     background-color: red;
 }



 .homeColumns {
     /* --animationDelay : 2.3s;
     -o-animation-delay: var(--animationDelay);
     -moz-animation-delay: var(--animationDelay);
     -webkit-animation-delay: var(--animationDelay);
     animation-delay: var(--animationDelay); */
     display: flex;
     width: 100%;
     height: 100%;
 }

 .pimg {
     width: 100%;
     height: auto;
 }

 /* Hide scrollbar for Chrome, Safari and Opera */
 .Portfolio::-webkit-scrollbar {
     display: none;
 }




 #bookTitle {
     font-weight: bold;
     /* height: 10%; */
 }


 .bookImages::-webkit-scrollbar {
     display: none;
 }

 #bookDesc {
     white-space: pre-wrap;
 }


 #loadingAppHome{
     width: 100%;
     height:100%;
     display: flex;
     align-items: center;
     justify-content: center;
 }

 #loadingApp {
     width: 100%;
     height: 100%;
     display: flex;
     align-items: center;
     justify-content: center;
 }

 .Router {
     width: 86.5%
 }

 #aboutTitle {
     font-weight: bold;
 }

 .NavRouter {
     width: 13.5%;
 }


 /* MOBILE */
 @media only screen and (max-width: 780px) {
     .App {
         flex-direction: column-reverse;
     }

     .Navigation {
         width: 100%;
         height: 100%;
         z-index: 100;
     }

     #NavContainer {
         width: 100%;
         height: 100%;
     }

    .homeColumns {
        --animationDelay : 3s;
        -o-animation-delay: var(--animationDelay);
        -moz-animation-delay: var(--animationDelay);
        -webkit-animation-delay: var(--animationDelay);
        animation-delay: var(--animationDelay);
    }
     
     .c{
        width: 100%;
        height: 100%;
     }

     .Home {
         width: 100%;
         height: 100%;
         display: flex;
         justify-content: center;
         align-items: center;
     }

     .NavRouter {
         width: 100%;
         height: 5%;
     }

     #NavContainer {
         display: flex;
         background-color: white;
     }

     .PortfolioPage {
         width: 100%;
         height: 100%;
         overflow: scroll;
     }

     .Portfolio {
         margin-top: 10px;
         display: flex;
         flex-direction: column;
         align-items: center;
     }

     .PhotoColumn {
         width: 25%;
         margin-right: 5px;
     }

     .pc_0 {
         margin-left: 5px;
     }

     .cimg {
         margin-top: 5px;
         margin-bottom: 5px;
     }

     #about {
         width: 80%;
         white-space: pre-wrap;
     }

     #title {
         z-index: 20;
         background-color: white;
         width: 50%;
         height: 100%;
         display: flex;
         align-items: center;
         padding-left: 10px;
     }

     #outer-container {
         width: 50%;
         height: 100%;
         display: flex;
         align-items: center;
         justify-content: flex-end;
         padding-right: 10px;
     }

     #photos {
         width: 85%;
         margin-bottom: 10px;
     }

     .DropDownMobile {
         position: absolute;
         top: 5%;
         right: 0;
         z-index: 1;
         width: 59%;
         height: 100%;
     }

     .DropDownAnimated {
         width: 100%;
         height: 100%;
         background-color: white;
     }

     .DropDownContainer{
         width: 100%;
         height: 95%;
         overflow-y: scroll;
     }

     .Section {
         padding-left: 10px;
     }

     .Router {
         width: 100%;
         height: 95%;
     }

     .Portfolio {
         overflow: scroll;
     }

     .bimg {
         transform: scale(0.95);
         width: 100%;
         height: auto;
         margin-bottom: 10px;
     }

     .bookWrapper {
         width: 100%;
         height: 100%;
         overflow: scroll;
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         overflow: scroll;
     }

     .BooksPage {
         width: 100%;
         height: 100%;
         overflow: scroll;
     }

     .Books {
         margin-top: 10px;
         display: flex;
         flex-direction: column;
         align-items: center;
     }

     .bookImages {
         width: 85%;
         margin-bottom: 10px;
     }

     #bookInfo {
         width: 80%;
         white-space: pre-wrap;
     }

     #sd {
         position: absolute;
         top: 0;
         left: 0;
         width: 100%;
     }

     .HoverImageWrapper {
         transform: scale(0.95);
         width: 100%;
         height: auto;
     }

     #Film {
         display: flex;
         align-items: center;
         justify-content: center;
         width: 90%;
         height: 30%;
         overflow: hidden;
         background-color: black;
     }

     .FilmInfo {
         margin-top: 5px;
         width: 90%;
         text-align: left;
         height: 15%;
     }


     .AboutData{
        width: 90%;
        height: 90%;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
     }

     #loading{
         width: 100%;
         height: 100%;
         display: flex;
         align-items: center;
         justify-content: center;
         flex-direction: column;
     }

     .homeColumns{
        --animationDelay : 1.2s;
        -o-animation-delay: var(--animationDelay);
        -moz-animation-delay: var(--animationDelay);
        -webkit-animation-delay: var(--animationDelay);
        animation-delay: var(--animationDelay);
     }

 }

 /* DESKTOP */
 @media only screen and (min-width: 781px) {
     .Home {
         z-index: 1;
         position: absolute;
         top: 0;
         left: 0;
         width: var(--PageWidth);
         height: 100%;
         display: flex;
         justify-content: center;
         align-items: center;
     }

     .Navigation {
         margin-top: 14;
         z-index: 1;
         font-size: 15px;
         height: 100%;
         width: 100%;
         padding-left: 2.5px;
     }

     #title {
         position: absolute;
         top: 14px;
         left: 15px;
         font-size: 1.5rem;
         z-index: 100;
         background-color: white;
     }

     .PortfolioPage {
         width: 100%;
         height: 100%;
         overflow: scroll;
         display: flex;
         justify-content: center;
         align-items: center;
     }

     .PhotoColumn {
         width: 20%;
         height: 100%;
         margin-left: 10px;
     }

     .pc_0 {
         margin-left: 10px;
     }

     .pc_4 {
         margin-right: 10px;
     }

     .Portfolio {
         -ms-overflow-style: none;
         scrollbar-width: none;
         display: flex;
         width: 100%;
         height: 100%;
         justify-content: center;
         align-items: center;
         z-index: 1;
         overflow-y: scroll;
     }

     .cimg {
         margin-bottom: 10px;
         margin-top: 10px;
     }

     #about {
         padding-left: 15;
         display: flex;
         width: 30%;
         height: 100%;
         /* justify-content: center; */
         align-items: center;
         white-space: pre-wrap;
     }

     #photos {
         flex-direction: column;
         align-items: center;
         width: 80%;
         height: 100%;
     }

     .bimg {
         margin: 10px;
         height: 100%;
         width: auto;
     }

     .bookWrapper {}

     .BooksPage {
         /* z-index: 1;
    position: absolute; */
         top: 0;
         left: 0;
         width: 100%;
         height: 100%;
         display: flex;
         justify-content: center;
         align-items: center;
     }

     .Books {
         overflow: hidden;
         display: flex;
         width: 100%;
         height: 100%;
         justify-content: center;
         align-items: center;
         flex-direction: column;
         width: 100%;
         height: 70%;
         overflow: hidden;
         padding-left: 15px;
     }

     .bookImages {
         display: flex;
         height: 70%;
         width: 100%;
         overflow-x: scroll;
         overflow-y: hidden;
         -ms-overflow-style: none;
         scrollbar-width: none;
     }

     .HoverImageWrapper {
         display: flex;
         align-items: center;
         flex-direction: column;
         justify-content: center;
         transform: scale(0.6);
         width: 80%;
         height: auto;
         /* padding-bottom: 12.5px; */
     }

     .pictureDescription {
         align-self: flex-end;
     }

     .NavRouter {
         z-index: 1;
     }

     #Film {
         width: 50%;
         height: 50%;
         background-color: black;
     }

     .FilmInfo {
         margin-top: 5px;
         width: 90%;
         text-align: left;
         height: 15%;
     }

     #bookInfo {
         width: 100%;
         height: 30%;
     }
 }


 #sectionTitle {
     font-weight: bold;
 }

 .SprawlButton {
     font-weight: bold;
 }

 #loading {
     display: flex;
     align-items: center;
     /* justify-content: space-between; */
 }

 .FilmsPage {}

 .FilmsPage::-webkit-scrollbar {
     display: none;
 }

 .FilmsPage {
     overflow-y: scroll;
     -ms-overflow-style: none;
     scrollbar-width: none;
     height: 100%;
     width: 100%;
 }

 .Films {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
 }

 .Film {
     width: 100%;
     height: 100%;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
 }




 #FilmTitle {
     font-weight: bold;
 }

 .About {
     width: 100%;
     height: 100%;
 }

 .AboutPage {
     width: 100%;
     height: 100%;
     display: flex;
     align-items: center;
     justify-content: center;
 }

 #aboutHeader {
     font-weight: bold;
 }

 .blur{
  filter: blur(10px);   
 }